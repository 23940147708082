import React, { useEffect, useState } from 'react'
import SEO from '../utils/SEO'
import { Card, Button, Grid } from '@mui/material';
import Webcam from "react-webcam";

import Popup from 'reactjs-popup';

import Header from '../components/Header';
import Footer from '../components/Footer';

import { useNavigate } from 'react-router-dom'

import { UserContext } from '../providers/UserProvider.js'

const videoConstraints = {
    aspectRatio: 0.7,
    facingMode: "environment",

};

export default function ImageFrame() {

    const [showPopupImageFrame, setShowPopupImageFrame] = useState(false);

    const handleShowPopupImageFrame = () => {
        setShowPopupImageFrame(true);
    };

    const handleHidePopupImageFrame = () => {
        setShowPopupImageFrame(false);
    };

    const [imageSrc, setImageSrc] = useState('')

    const navigate = useNavigate()
    const { user, setUserData, activeButtons, setActiveButtons, image, setImageData } = React.useContext(UserContext)

    useEffect(() => {

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const license = localStorage.getItem('license');
        const dni = localStorage.getItem('dni');
        const image = localStorage.getItem('image');


        if (email === null) {
            navigate('/')
        }

        setUserData({ email, password });
        setActiveButtons({ dni, license });
        setImageData({ image })
        handleShowPopupImageFrame();

    }, [])

    const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            const pictureSrc = webcamRef.current.getScreenshot();
            setImageSrc(pictureSrc)

            setImageData({ image: pictureSrc })
            localStorage.setItem('image', pictureSrc)

        },
        [webcamRef]
    );

    function handleRepeat() {
        setImageSrc('');
        setImageData({ image: '' })
        localStorage.setItem('image', '')
    }

    function handleSend() {
        setActiveButtons({ license: true })
        localStorage.setItem('license', true.toString())
        navigate('/verificacion')
    }

    return (
        <>
            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='imageFrame' style={{ backgroundColor: '#1F2A39' }}>

                <div className='imageFrame__overlay'>
                    <Header />
                    <div className='m-b-l'>
                        <Card sx={{ borderRadius: '16px' }} className='content'>

                            {imageSrc === '' ? (
                                <Webcam
                                    className='frame'
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                            ) : (
                                <img className='still_frame' src={imageSrc} />
                            )}

                        </Card>
                        {imageSrc === '' ? (
                            <Button size='large' fullWidth className='p-b' onClick={capture} text="Send" variant="contained">Capturar</Button>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button size='large' fullWidth className='p-b' onClick={handleRepeat} text="Send" variant="contained">Repetir</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button size='large' fullWidth className='p-b' onClick={handleSend} text="Send" variant="contained">Enviar</Button>
                                </Grid>
                            </Grid>
                        )}

                    </div>
                    <Footer />
                </div>
                <Popup open={showPopupImageFrame} onClose={handleHidePopupImageFrame}>
                    <div className="popup-container" id="popup">
                        <div className="popup">
                            <h2 className="popup-title">Información importante</h2>
                            <p className="popup-message">Si no dispones de una licencia física, por favor toma una foto aleatoria de cualquier objeto para poder continuar con el proceso de alta.</p>
                            <button className="popup-close" onClick={handleHidePopupImageFrame}>Aceptar</button>
                        </div>
                    </div>
                </Popup>
            </section>
        </>
    )
}
