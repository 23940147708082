import React, { useEffect, useState } from 'react'

import Popup from 'reactjs-popup';
import { isChrome, isSafari, isFirefox, isIE, isEdge, isOpera, isIOS, isAndroid, isDesktop, osName, browserName } from 'react-device-detect';

export default function Header() {

    const [info, setInfo] = useState({ os: "", browser: "" });
    const [osCheck, setOsCheck] = useState(false);

    const [showPopupOs, setShowPopupOs] = useState(false);

    const handleHidePopupOs = () => {
        setShowPopupOs(false);
    };

    const handleAcceptPopupOsCheck = () => {
        setShowPopupOs(false);
        setOsCheck(true);
        localStorage.setItem('os_check', true.toString());
    }

    useEffect(() => {
        const os = getOS();
        const browser = getBrowser();
        setInfo({ os, browser });
        setOsCheck(localStorage.getItem('os_check'));
    }, []);

    useEffect(() => {
        console.log(info);
        if (info.os !== "" && info.browser !== "" && !isAllowed(info.os, info.browser)) {
            if (!osCheck) {
                setShowPopupOs(true);
            }
        }
    }, [info]);

    const getOS = () => {
        const userAgent = window.navigator.userAgent;
        let os = "";

        if (isIOS) os = "iPhone";
        else if (isAndroid) os = "Android";
        else if (isDesktop) os = "Desktop";
        else os = osName
        return os;
    };

    const getBrowser = () => {
        const userAgent = window.navigator.userAgent;
        let browser = "";

        if (isChrome) browser = "Chrome";
        else if (isFirefox) browser = "Firefox";
        else if (isSafari) browser = "Safari";
        else if (isIE) browser = "IE";
        else if (isEdge) browser = "Edge";
        else if (isOpera) browser = "Opera";
        else browser = "indeterminado";

        return browser;
    };

    const isAllowed = (os, browser) => {
        if (os === 'Desktop') {
            if (browser !== 'Chrome' && browser !== 'Firefox' && browser !== 'Opera' && browser !== 'Edge' && browser !== 'Safari') {
                return false;
            } else return true;

        } else if (os === 'Android') {
            if (browser !== 'Chrome' && browser !== 'Firefox' && browser !== 'Opera') {
                return false;
            } else return true;

        } else if (os === 'iPhone') {
            if (browser !== 'Safari') {
                return false;
            } else return true;
        }
    };

    return (

        <div className='header__content'>
            <p className='header__text'>GOLF</p>
            <p className='header__text'>EXCLUSIVE</p>
            <p className='header__text'>CLUB</p>

            <Popup open={showPopupOs} onClose={handleHidePopupOs}>
                <div className="popup-container" id="popup">
                    <div className="popup">
                        <div className="popup-terms m-b-l">

                            <label style={{ fontSize: '14px', lineHeight: '14px', textAlign: 'left' }}>
                                <p>
                                    Parece que estás utilizando un navegador o sistema operativo que no está actualmente
                                    soportado por nuestra plataforma. Esto puede provocar problemas en la verificación
                                    de DNI y el reconocimiento de identidad.
                                </p>
                                <p>
                                    Te sugerimos utilizar los siguientes navegadores y sistemas operativos para una experiencia óptima:
                                </p>

                                <ul>
                                    <li><b>iPhone:</b> Utiliza Safari para la mejor compatibilidad.</li>
                                    <li><b>Android:</b> Recomendamos el uso de Chrome, Firefox u Opera.</li>
                                    <li><b>Desktop:</b> Puedes usar Chrome, Firefox, Opera, Safari o Edge.</li>
                                </ul>

                                <p>
                                    Si continúas utilizando un navegador o sistema operativo no soportado, podrías experimentar
                                    problemas de funcionalidad o incompatibilidades. Te agradecemos tu comprensión y cooperación
                                    para asegurar una experiencia sin problemas en nuestra plataforma.
                                </p>
                            </label>
                        </div>
                        <button className="popup-close m-r-s" onClick={handleHidePopupOs} >Cancelar</button>
                        <button className="popup-close m-l-s" onClick={handleAcceptPopupOsCheck} >Aceptar</button>
                    </div>
                </div>
            </Popup>
        </div>



    )
}