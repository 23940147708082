import React from 'react';
import SEO from '../utils/SEO';
import { useNavigate } from 'react-router-dom';
import { login } from '../adapters/api';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { TextField, Card, FormGroup, Button } from '@mui/material';
import backgroundImage from "../assets/images/backgrounds/gambito_background.jpg";
import { UserContext } from '../providers/UserProvider.js'
import formFooter from "../assets/images/logos/form-footer.png";
import Header from '../components/Header';
import Footer from '../components/Footer';




const CustomTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&.Mui-focused': {
            backgroundColor: 'white',
            borderColor: theme.palette.primary.main,
        },
    },
}));

const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = React.useState()
    const [password, setPassword] = React.useState()
    const { setUserData } = React.useContext(UserContext)
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {

            const res = await login(data.get('email'), data.get('password'));
            if (res) {
                if (res.data.token) {
                    localStorage.setItem('auth-token', res.data.token);
                    setUserData({ email, password })
                    localStorage.setItem('email', email)
                    navigate('/detalles');

                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRouter = async (event) => {
        navigate('/verificacion');
    };

    return (
        <>

            <SEO title="Gambito | Login" description="Gambito login page" />

            <section className='singIn' style={{ backgroundImage: `url(${backgroundImage})` }}>

                <div className='singIn__overlay'>
                    <Header />
                    <Grid container className="singIn__content">

                        <Grid item xs={12} className="text-col">
                            <p className='singIn__title'>Inicia sesión</p>
                        </Grid>

                        <Grid item xs={12} >
                            <Card className='singIn__Card'>
                                <Grid >
                                    <form>
                                        <FormGroup component="form" className='singIn__formGroup' onSubmit={handleSubmit}>
                                            <p className='singIn__form_title'>Introduzca sus datos de <b>correo electrónico y contraseña</b> para iniciar sesión.</p>

                                            <CustomTextField
                                                label="Correo electrónico"
                                                variant="filled"
                                                fullWidth
                                                type={'email'}
                                                className='singIn_input'
                                                size='small'
                                                id="email"
                                                autoComplete="email"
                                            />

                                            <CustomTextField
                                                label="Contraseña"
                                                variant="filled"
                                                fullWidth
                                                type={'password'}
                                                className='singIn_input'
                                                size='small'
                                                id="password"
                                                autoComplete="current-password"

                                            />

                                            <Button type="submit" onClick={handleRouter} size='large' color='primary' className='singIn_button' text="Send" variant="contained" >Iniciar sesión</Button>

                                        </FormGroup>
                                    </form>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <p className='singIn__form_footer'>
                                            by
                                        </p>
                                        <img src={formFooter} alt="veridas logo" style={{ height: '18px' }} />
                                    </div>
                                </Grid>
                            </Card>
                            <p style={{ padding: "2rem" }} className='singIn__form_footer'>Si no tienes una cuenta, <a href='/'>Registrate</a></p>
                        </Grid>
                        <Footer />
                    </Grid>
                </div>
            </section>
        </>
    )
};

export default Login;
