import React, { useEffect, useState } from 'react'
import SEO from '../utils/SEO'
import Grid from '@mui/material/Grid';
import { Card, FormGroup, Button } from '@mui/material';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Popup from 'reactjs-popup';

import { useNavigate } from 'react-router-dom'

export default function Verification() {

    const [user, setUserData] = useState({});
    const [activeButtons, setActiveButtons] = useState({});
    const [veridasTerms, setVeridasTerms] = useState(false);

    const navigate = useNavigate()

    const [showPopupValidasTerms, setShowPopupValidasTerms] = useState(false);

    const handleShowPopupValidasTerms = () => {
        setShowPopupValidasTerms(true);
    };

    const handleHidePopupValidasTerms = () => {
        setShowPopupValidasTerms(false);

    };

    const handleAcceptPopupValidasTerms = () => {
        setShowPopupValidasTerms(false);
        setVeridasTerms(true);
        localStorage.setItem('veridas_terms', true.toString());
        navigate('/veridas')
    }


    useEffect(() => {

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const license = localStorage.getItem('license');
        const dni = localStorage.getItem('dni');
        setVeridasTerms(localStorage.getItem('veridas_terms'));

        if (email === null) {
            navigate('/')
        }

        setUserData({ email, password });
        setActiveButtons({ dni, license });

    }, [])

    const handleClickDNI = (flagDNI) => {
        if (!activeButtons.dni) {
            if (!veridasTerms) {
                handleShowPopupValidasTerms();
            } else {
                navigate('/veridas')
            }
        }
    };

    const handleClickNext = (flagSelfie) => {
        navigate('/detalles')
    };

    const handleClickGolf = (flagGolf) => {
        navigate('/imagen')
    };




    return (
        <>

            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='verification' style={{ backgroundColor: '#1F2A39' }}>

                <div className='verification__overlay'>

                    <Grid container className="verification__content">

                        <Header />

                        <Grid item xs={12} className="text-col">
                            <p className='verification__title'>Bienvenido</p>
                            <div className='verification__text m-b-xl'>
                                <p>Estás a punto de formar parte del <b>Golf Exclusive Club.</b></p>
                                <p>Tu pertenencia a este club es imprescindible para poder participar en cualquiera de las pruebas del Circuito.</p>
                                <p>Solo necesitamos que nos facilites algunos datos.</p>
                            </div>
                        </Grid>

                        <Grid item xs={12} >
                            <Card className='verification__Card'>
                                <Grid >
                                    <FormGroup className='verification__formGroup m-t-s m-b-s'>


                                        <Button
                                            size='large'
                                            onClick={handleClickDNI}
                                            color={activeButtons.dni ? "primary" : "secondary"}
                                            className='verification__button'
                                            text="Send"
                                            variant={activeButtons.dni ? "contained" : "outlined"}
                                        >
                                            Foto del DNI y Selfie
                                        </Button>

                                        <Button
                                            size='large'
                                            onClick={handleClickGolf}
                                            color={activeButtons.license ? "primary" : "secondary"}
                                            className='verification__button verification__button_margin'
                                            text="Send"
                                            variant={activeButtons.license ? "contained" : "outlined"}
                                        >
                                            Foto de la Licencia
                                        </Button>

                                        <Button
                                            size='large'
                                            onClick={handleClickNext}
                                            color={"secondary"}
                                            className='verification__button verification__button_margin'
                                            text="Send"
                                            variant={"outlined"}
                                            disabled={!activeButtons.license || !activeButtons.dni}
                                        >
                                            Continuar
                                        </Button>

                                    </FormGroup>
                                </Grid>
                            </Card>

                        </Grid>

                        <Footer />

                    </Grid>
                </div>

                <Popup open={showPopupValidasTerms} onClose={handleHidePopupValidasTerms}>
                    <div className="popup-container" id="popup">
                        <div className="popup">
                            <div className="popup-terms m-b-l">

                                <label className="terms">
                                    <p>
                                        GALERA GOLF SL, como Responsable del tratamiento de datos, utilizará sus datos
                                        personales para darle de alta en sus sistemas y verificar su identidad para acceder a las
                                        instalaciones. Para poder llevar a cabo estos fines, va a ser necesario generar y conservar
                                        datos biométricos a partir de las fotografías que nos va a facilitar
                                    </p>
                                    <p>
                                        Este es un procedimiento voluntario para el que el Responsable debe recabar su
                                        consentimiento informado.
                                    </p>
                                    <p>
                                        Le amparan sus derechos de acceso, rectificación, oposición y supresión (del modo que
                                        establece la legislación), limitación del tratamiento y portabilidad, a no ser objeto de
                                        decisiones automatizadas y a revocar su consentimiento en cualquier momento cuando se
                                        haya dado, derechos que podrá ejercitar ante GALERA GOLF SL adjuntando copia de un
                                        documento acreditativo de su identidad, a través del correo marketing@galeragroup.com o
                                        en la dirección Carrer Mallorca 230, Principal Primera 08008 Barcelona. También podrá
                                        ejercitar sus derechos ante la Agencia Española de Protección de Datos (www.aepd.es).
                                    </p>
                                    <p>
                                        GALERA GOLF SL, para el desarrollo de sus actividades, puede requerir la cesión o
                                        transferencia de sus datos a las Administraciones Públicas que estén legitimadas para ello,
                                        así como a MAHOU, S.A., A-28.078.202, Calle Titán 15, CP 28045, Madrid
                                    </p>
                                    <p className='popup_term_links'>
                                        Si quiere saber más puede consultar el siguiente enlace <a target='_blank' rel='noreferrer' href='https://avantgardeit.es/wp-content/uploads/2023/05/Textos-legales-Gambito.pdf'>[enlace a los T&C de uso del
                                        Servicio y Política de Privacidad].</a>
                                    </p>
                                </label>
                            </div>
                            <button className="popup-close m-r-s" onClick={handleHidePopupValidasTerms} >Cancelar</button>
                            <button className="popup-close m-l-s" onClick={handleAcceptPopupValidasTerms} >Aceptar</button>
                        </div>
                    </div>
                </Popup>

            </section>
        </>
    )
}
