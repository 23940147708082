import React, { useEffect, useState } from 'react';
import SEO from '../utils/SEO'
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { TextField, Card, FormGroup, Button } from '@mui/material';
import backgroundImage from "../assets/images/backgrounds/gambito_background.jpg";
import formFooter from "../assets/images/logos/form-footer.png";
import { UserContext } from '../providers/UserProvider.js'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom'
import { checkUser } from '../adapters/api'
import Popup from 'reactjs-popup';

const CustomTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&.Mui-focused': {
            backgroundColor: 'white',
            borderColor: theme.palette.primary.main,
        },
    },
}));


export default function Register() {

    const navigate = useNavigate()
    const [email, setEmail] = React.useState()
    const [password, setPassword] = React.useState()
    const { setUserData } = React.useContext(UserContext)

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        localStorage.clear()
    }, [])

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleHidePopup = () => {
        setShowPopup(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setUserData({ email, password })
        try {
            const { data } = await checkUser(email);
            const { exist } = data 
            if (!exist) {
                localStorage.clear();
                localStorage.setItem('email', email)
                localStorage.setItem('password', password)
                navigate('/verificacion')
            } else {
                handleShowPopup();
            }
        } catch (error) {

        }

    }
    return (
        <>

            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='singIn' style={{ backgroundImage: `url(${backgroundImage})` }}>

                <div className='singIn__overlay'>

                    <Grid container className="singIn__content">

                        <Header />

                        <Grid item xs={12} className="text-col">
                            <p className='singIn__title'>Regístrate</p>
                        </Grid>

                        <Grid item xs={12} >
                            <Card className='singIn__Card'>
                                <Grid >
                                    <form onSubmit={e => handleSubmit(e)}>
                                        <FormGroup className='singIn__formGroup'>
                                            <p className='singIn__form_title'>Introduzca sus datos de <b>correo electrónico y contraseña</b> para crear una cuenta</p>

                                            <CustomTextField
                                                label="Correo electrónico"
                                                variant="filled"
                                                fullWidth
                                                type={'email'}
                                                className='singIn_input'
                                                size='small'
                                                id="email"
                                                autoComplete="on"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />

                                            <CustomTextField
                                                label="Contraseña"
                                                variant="filled"
                                                fullWidth
                                                type={'password'}
                                                className='singIn_input'
                                                size='small'
                                                id="password"
                                                autoComplete="password"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />

                                            <Button type="submit" size='large' color='primary' className='singIn_button' text="Send" variant="contained" >Crear una cuenta</Button>
                                            <Popup open={showPopup} onClose={() => setShowPopup(false)}>
                                                <div className="popup-container" id="popup">
                                                    <div className="popup">
                                                        <h2 className="popup-title">¡Error!</h2>
                                                        <p className="popup-message">El correo electrónico ya está registrado.</p>
                                                        <button className="popup-close" onClick={handleHidePopup}>Cerrar</button>
                                                    </div>
                                                </div>
                                            </Popup>
                                        </FormGroup>
                                    </form>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <p className='singIn__form_footer'>
                                            by
                                        </p>
                                        <img src={formFooter} alt="veridas logo" style={{ height: '18px' }} />
                                    </div>

                                </Grid>
                            </Card>
                            <p style={{ padding: "2rem" }} className='singIn__form_footer'>{/*Si ya tienes una cuenta, <a href='/login'>Inicia sesión</a>*/}</p>
                        </Grid>

                        <Footer />

                    </Grid>
                </div>
            </section>
        </>
    )
}
