import React, { useEffect, useState } from 'react'
import SEO from '../utils/SEO'
import { Card } from '@mui/material';
import { UserContext } from '../providers/UserProvider.js'
import { useNavigate } from 'react-router-dom'

import Popup from 'reactjs-popup';

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function ValidasFrame() {

    const [token, setToken] = React.useState(undefined)
    const { user, setUserData, setActiveButtons } = React.useContext(UserContext)
    const navigate = useNavigate()

    const [showPopupValidasError, setShowPopupValidasError] = useState(false);

    const handleShowPopupValidasError = () => {
        setShowPopupValidasError(true);
    };

    const handleHidePopupValidasError = () => {
        setShowPopupValidasError(false);
        window.location.reload(false);
    };

    useEffect(() => {
        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const license = localStorage.getItem('license');
        const dni = localStorage.getItem('dni');

        if (email === null) {
            navigate('/')
        }

        setUserData({ email, password });
        setActiveButtons({ dni, license });

    }, [])

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/veridas/token`)
            .then(res => res.json())
            .then(data => {
                setToken(data.token)
                setUserData({ ...user, validationId: data.validationId })
            })
    }, [])

    window.addEventListener("message", receiveMessage, false);
    function receiveMessage(event) {

        if (event.origin.includes("xpressid") && event.data.code === "ProcessCompleted") {
            const dataFromXpressID = event.data;
            const { validationId } = dataFromXpressID.additionalData

            localStorage.setItem('validate_id', validationId);

            if (dataFromXpressID.additionalData.globalScores.biometryScores[0].value > 0.7 &&
                dataFromXpressID.additionalData.globalScores.biometryScores[1].value > 0.7 &&
                dataFromXpressID.additionalData.globalScores.documentScores[0].value > 0.7 &&
                dataFromXpressID.additionalData.globalScores.integrityScores[0].value > 0.7) {
                setActiveButtons({ dni: true })
                localStorage.setItem('dni', true.toString());
                navigate('/verificacion')

            } else {
                handleShowPopupValidasError();
            }
        }

        if(event.origin.includes("xpressid") && event.data.code === "ProcessError") {
            setShowPopupValidasError(true)
        }
    }

    return (
        <>
            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='validas' style={{ backgroundColor: '#1F2A39' }}>

                <div className='validas__overlay'>
                    <Header />
                    
                    <Card sx={{ borderRadius: '16px' }} className='content'>

                        {
                            token !== undefined &&
                                <iframe
                                    title="XpressID-iframe"
                                    id="XpressID-iframe"
                                    allow="camera; microphone; geolocation;"
                                    src={`${process.env.REACT_APP_XPRESSID_URL}?access_token=${token}`}
                                    style={{ width: '100%', height: '100%', border: 'none' }}
                                />
                        }

                    </Card>

                    <Footer />
                </div>
                <Popup open={showPopupValidasError} onClose={handleHidePopupValidasError}>
                    <div className="popup-container" id="popup">
                        <div className="popup">
                            <h2 className="popup-title">Error en la verificación</h2>
                            <p className="popup-message">Se ha producido un fallo en la verificacion, vuelva a intentarlo.</p>
                            <button className="popup-close" onClick={handleHidePopupValidasError}>Repetir</button>
                        </div>
                    </div>
                </Popup>
            </section>
        </>
    )
}
