import React, { useEffect, useState } from 'react';
import SEO from '../utils/SEO';
import { FormGroup, FormControlLabel, Checkbox, Link, Button, CircularProgress, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

import { UserContext } from '../providers/UserProvider.js';
import { register, checkUser } from '../adapters/api'

import { red } from '@mui/material/colors';

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Permissions() {


    function formatDate(dateStr) {
        // Separamos la fecha en día, mes y año
        const [day, month, year] = dateStr.split(" ");
        // Formateamos la fecha al formato deseado
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    const [checkboxValues, setCheckboxValues] = useState([false, false, false, false, false, false, false]);
    const checkboxLabels = [
        'premium_circuit_communications',
        'other_tournaments_communications',
        'mahou_san_miguel_communications',
        'other_partners_communications',
        'authorization_image_use',
        'acceptance_policies',
        'accept_terms_conditions',
        'Politicas de comunicaciones'];

    const checkboxesWithLabels = checkboxValues.map((value, index) => {
        const label = checkboxLabels[index];
        return {
            label,
            value
        };
    });



    const navigate = useNavigate()
    const { user, setUserData, activeButtons, setActiveButtons, image, setImageData } = React.useContext(UserContext)
    const [userImage, setUserImage] = React.useState('')
    const [numLicencia, setNumLicencia] = React.useState('')
    const [clubOrigen, setclubOrigen] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [nacimiento, setNacimiento] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [numDNI, setDNI] = React.useState('')
    const [validateID, setValidateID] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)

    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');

    const [showPopupError, setShowPopupError] = useState(false);

    const handleShowPopupError = () => {
        setShowPopupError(true);
    };

    const handleHidePopupError = () => {
        setShowPopupError(false);
    };

    useEffect(() => {

        setDNI(localStorage.getItem('numDNI'));
        setNacimiento(localStorage.getItem('birthDate'));
        setFullName(localStorage.getItem('fullName'));
        setNumLicencia(localStorage.getItem('num_licencia'));
        setclubOrigen(localStorage.getItem('club_origen'));
        setPhone(localStorage.getItem('phone'));
        setUserImage(localStorage.getItem('userImage'));
        setImageData(localStorage.getItem('image'));
        setValidateID(localStorage.getItem('validate_id'));

        if (email === null) {
            navigate('/')
        }

    }, [])

    const handleCheckboxChange = (index) => {
        const newCheckboxValues = [...checkboxValues];
        newCheckboxValues[index] = !newCheckboxValues[index];
        setCheckboxValues(newCheckboxValues);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const checkedLabels = [];
        for (let i = 0; i < checkboxValues.length; i++) {
            if (checkboxValues[i]) {
                checkedLabels.push(checkboxLabels[i]);
            }
        }

        const json = JSON.stringify({
            "email": email,
            "password": password,
            "validate_id": validateID,
            "full_name": fullName,
            "dni": numDNI,
            "birth_date": formatDate(nacimiento),
            "license_number": numLicencia,
            "origin_club": clubOrigen,
            "phone_number": phone,
            "card_image": image,
            "avatar": userImage,
            "permissions": checkedLabels
        });

        try {

            const customConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const res = await register(json, customConfig);
            const { status } = res.request
            if (res) {
                if (status === 201) {
                    localStorage.clear();
                    setIsLoading(false);
                } else {
                    handleShowPopupError();
                    setIsLoading(false);
                }
                navigate('/completado');
            }
        } catch (error) {

        }

    }

    return (
        <>

            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='permissions' style={{ backgroundColor: '#1F2A39' }}>

                <div className='permissions__overlay'>

                    <Header />
                    <p className='permissions__title'>Permisos y privacidad</p>
                    <form onSubmit={e => handleSubmit(e)}>
                        <FormGroup className='permissions__formGroup m-t-s m-b-s' >

                            <FormControlLabel
                                control={<Checkbox sx={{ color: red[900], '&.Mui-checked': { color: red[900], }, }} required checked={checkboxValues[0]} onChange={() => handleCheckboxChange(0)} />}
                                label={<div><span className='checkbox_required'>*</span>Permito la recepción de comunicaciones del Circuito Premium Mahou-San Miguel. Información relevante de los circuitos, calendarios, actualizaciones del circuito, etc.</div>}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={checkboxValues[1]} onChange={() => handleCheckboxChange(1)} />}
                                label="Permito la recepción de comunicaciones relativas a otros Torneos, Pruebas, Torneos Profesionales, PROAMS y otras informaciones de Gambito Golf."
                            />
                            <FormControlLabel
                                control={<Checkbox checked={checkboxValues[2]} onChange={() => handleCheckboxChange(2)} />}
                                label="Permito la recepción de comunicaciones de Mahou-San Miguel con ofertas, información de productos, etc."
                            />
                            <FormControlLabel
                                control={<Checkbox checked={checkboxValues[3]} onChange={() => handleCheckboxChange(3)} />}
                                label="Permito la recepción de comunicaciones de otros partners del circuito con ofertas, información de productos, etc."
                            />
                            <FormControlLabel
                                control={<Checkbox checked={checkboxValues[4]} onChange={() => handleCheckboxChange(4)} />}
                                label="Autorizo al Circuito Premium Mahou-San Miguel a hacer uso de mi imagen en fotografías y vídeos que se puedan producir a lo largo de las pruebas."
                            />

                            <FormControlLabel
                                control={<Checkbox sx={{ color: red[900], '&.Mui-checked': { color: red[900], }, }} required checked={checkboxValues[5]} onChange={() => handleCheckboxChange(5)} />}
                                label={
                                    <div>
                                        <span><span className='checkbox_required'>*</span>Estoy de acuerdo con los </span>
                                        <Link href={'http://www.gambitogolf.es/aviso-legal/'}>Términos y Condiciones</Link>
                                        <span>, </span>
                                        <Link href={'http://www.gambitogolf.es/politica-de-cookies/'} component='a'>Política de Cookies</Link>
                                        <span> y </span>
                                        <Link href={'https://www.gambitogolf.es/politica-de-privacidad/'}>Política de Privacidad</Link>
                                        <span> de Gambito Golf.</span>
                                    </div>} />
                            <FormControlLabel
                                control={<Checkbox sx={{ color: red[900], '&.Mui-checked': { color: red[900], }, }} required checked={checkboxValues[6]} onChange={() => handleCheckboxChange(6)} />}
                                label={<div><span className='checkbox_required'>*</span>Acepto todas las políticas y comunicaciones.</div>}

                            />
                            <Button
                                fullWidth
                                size='small'
                                color='primary'
                                className='permissions_button'
                                text="Send"
                                type='submit'
                                variant="contained"
                            >
                                Enviar
                            </Button>

                        </FormGroup>
                    </form>
                    <Footer />
                </div>

                <Popup open={showPopupError} onClose={() => setShowPopupError(false)}>
                    <div className="popup-container" id="popup">
                        <div className="popup">
                            <h2 className="popup-title">¡Error!</h2>
                            <p className="popup-message">Ha habido un problema con el registro, vuelva a intentarlo.</p>
                            <button className="popup-close" onClick={handleHidePopupError}>Cerrar</button>
                        </div>
                    </div>
                </Popup>
                <Modal open={isLoading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </Modal>
            </section>

        </>
    )
}
