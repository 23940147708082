import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Login from '../pages/Login';
import Register from '../pages/Register';
import Verification from '../pages/Verification';
import Permissions from '../pages/Permissions';
import ImageFrame from '../pages/ImageFrame';
import ValidasFrame from '../pages/ValidasFrame';
import Details from '../pages/Details';
import Completion from '../pages/Completion';


export default function Router() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Register />}/>
            <Route path="/login" element={<Login />} />
            <Route path="/verificacion" element={<Verification />} />
            <Route path="/permisos" element={<Permissions />} />
            <Route path="/imagen" element={<ImageFrame />} />
            <Route path="/detalles" element={<Details />} />
            <Route path="/veridas" element={<ValidasFrame/>} />
            <Route path="/completado" element={<Completion/>} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}
