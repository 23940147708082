import React from 'react'
import footerImage from "../assets/images/logos/footer-logo.svg";

export default function Header() {
    return (
        <img
            src={footerImage}
            loading="lazy"
            alt='Footer logo'
            className='footer__image'
        />
    )
}