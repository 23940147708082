import React, { createContext, useState } from 'react'

export const UserContext = createContext(undefined)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})

  const [activeButtons, setActiveButtons] = useState({ 
    dni: false,
    license: false
  })

  const [image, setImage] = useState({})

  const ctx = {
    user,
    setUserData: (data) => setUser(data),
    activeButtons,
    setActiveButtons: (data) => setActiveButtons(data),
    image,
    setImageData:(data => setImage(data))
  }
  
  return(
    <UserContext.Provider value={ctx}>
      { children }
    </UserContext.Provider>
  )
}
