import React, { useEffect, useState } from 'react'
import SEO from '../utils/SEO'
import { styled } from '@mui/material/styles';
import backgroundImage from "../assets/images/backgrounds/gambito_background.jpg";
import { Avatar, Chip, FormControl, TextField, Button, Grid } from '@mui/material';
import footerImage from "../assets/images/logos/footer-logo.svg";
import headerImage from "../assets/images/logos/header-logo.svg";
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../providers/UserProvider.js'

import Popup from 'reactjs-popup';


const CustomTextField = styled((props) => (
    <TextField InputProps={{}} {...props} />
))(({ theme }) => ({
    '& .MuiOutlinedInput-root': {

        overflow: 'hidden',
        height: '45px',
        borderRadius: 16,
        backgroundColor: theme.palette.mode === 'light' ? '#EBEDF20d' : '#2b2b2b',
        '&:hover': {
            backgroundColor: '#EBEDF21d',
        },
        "& input::placeholder": {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '17px',
            color: '#EA0000',
            opacity: 0.7,
        }
    },
}));



export default function Details() {

    const navigate = useNavigate()


    const [numLicencia, setNumLicencia] = React.useState('')
    const [clubOrigen, setclubOrigen] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [numDNI, setDNI] = React.useState('')
    const [nacimiento, setNacimiento] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [userImage, setUserImage] = React.useState('')


    const { user, setUserData, activeButtons, setActiveButtons } = React.useContext(UserContext)

    const [showPopupMaxSize, setShowPopupMaxSize] = useState(false);

    const handleShowPopupMaxSize = () => {
        setShowPopupMaxSize(true);
    };

    const handleHidePopupMaxSize = () => {
        setShowPopupMaxSize(false);
    };

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const validationId = localStorage.getItem('validate_id');
        fetch(`${process.env.REACT_APP_API_URL}/api/veridas/userData/${validationId}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const { fullName, dni, birthDate } = result.responseData
                setUserData({
                    ...user,
                    fullName,
                    dni,
                    birthDate
                })
                localStorage.setItem('fullName', fullName);
                localStorage.setItem('numDNI', dni);
                localStorage.setItem('birthDate', birthDate);
                setDNI(dni);
                setNacimiento(birthDate);
                setFullName(fullName);
            })
            .catch(error => console.log('error', error));

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const license = localStorage.getItem('license');
        const dni = localStorage.getItem('dni');
        setNumLicencia(localStorage.getItem('num_licencia'));
        setclubOrigen(localStorage.getItem('club_origen'));
        setPhone(localStorage.getItem('phone'));
        setUserImage(localStorage.getItem('userImage'));

        if (email === null) {
            navigate('/')
        }

        setUserData({ email, password });
        setActiveButtons({ dni, license });

    }, [])

    const handleUserImage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        const MAX_FILE_SIZE = 1024;
        if (file) {
            reader.onload = () => {
                const fileSizeKiloBytes = file.size / 1024;
                if (fileSizeKiloBytes < MAX_FILE_SIZE) {
                    setUserImage(reader.result);
                    localStorage.setItem('userImage', reader.result)
                } else {
                    handleShowPopupMaxSize();
                }

            };
            reader.readAsDataURL(file);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setNumLicencia(numLicencia)
        setclubOrigen(clubOrigen)
        setPhone(phone)
        localStorage.setItem('num_licencia', numLicencia)
        localStorage.setItem('club_origen', clubOrigen)
        localStorage.setItem('phone', phone)
        navigate('/permisos')
    }



    return (
        <>
            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='details__header' style={{ backgroundImage: `url(${backgroundImage})` }}></section>
            <section className='details' style={{ backgroundColor: '#1F2A39' }}>

                <div className='details__overlay'>

                    <Avatar
                        sx={{ width: '12vh', height: '12vh' }}
                        className='details__profile_img'
                        alt="User Picture"
                        src={userImage}
                    />
                    <input type="file" accept="image/png, image/jpeg" onChange={handleUserImage} className='details__input' />
                    <Chip
                        size='small'
                        sx={{ position: 'absolute', top: '19.5vh', width: '60px' }}
                        label="PRO"
                        color='primary'
                    />
                    <p className='details__title'>{fullName}</p>
                    <p className='details__subtitle'>Golf Exclusive Club</p>

                    <FormControl style={{ width: '90%' }}>
                        <form onSubmit={handleSubmit}>
                            <p className='details__textfield_value'>Documento Nacional de Identidad</p>
                            <CustomTextField
                                inputProps={{ min: 0, style: { textAlign: 'center', color: '#FFFFFF' } }}
                                required
                                className='details__textfield'
                                variant="outlined"
                                value={numDNI}
                                fullWidth
                                type={'text'}
                                size='small'
                            />

                            <p className='details__textfield_value'>Fecha de Nacimiento</p>
                            <CustomTextField
                                inputProps={{ min: 0, style: { textAlign: 'center', color: '#FFFFFF' } }}
                                required
                                className='details__textfield'
                                variant="outlined"
                                value={nacimiento}
                                fullWidth
                                type={'text'}
                                size='small'

                            />

                            <p className='details__textfield_value'>Nº Licencia*</p>
                            <CustomTextField
                                inputProps={{ min: 0, style: { textAlign: 'center', color: '#B7935B' } }}
                                required
                                className='details__textfield'
                                variant="outlined"
                                placeholder="A completar"
                                fullWidth
                                value={numLicencia}
                                id="num_licencia"
                                type={'text'}
                                onChange={(e) => setNumLicencia(e.target.value)}
                                size='small'

                            />

                            <p className='details__textfield_value'>Club de Origen*</p>
                            <CustomTextField
                                inputProps={{ min: 0, style: { textAlign: 'center', color: '#B7935B' } }}
                                required
                                className='details__textfield'
                                variant="outlined"
                                placeholder="A completar"
                                fullWidth
                                value={clubOrigen}
                                type={'text'}
                                id="club_origen"
                                onChange={(e) => setclubOrigen(e.target.value)}
                                size='small'

                            />

                            <p className='details__textfield_value'>Nº de teléfono*</p>
                            <CustomTextField
                                inputProps={{ min: 0, style: { textAlign: 'center', color: '#B7935B' } }}
                                required
                                className='details__textfield'
                                variant="outlined"
                                placeholder="A completar"
                                fullWidth
                                value={phone}
                                type={'tel'}
                                id="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                size='small'

                            />
                            <Button type='submit' fullWidth size='large' color='primary' className='details_button' text="Send" variant="contained" >Continuar</Button>
                        </form>
                    </FormControl>

                    <Grid container className='m-t-xl image'>

                        <Grid>
                            <img
                                src={headerImage}
                                loading="lazy"
                                alt='Header logo'
                                className='header__image'
                            />
                        </Grid>
                        <Grid>
                            <img
                                src={footerImage}
                                loading="lazy"
                                alt='Footer logo'
                                className='footer__image'
                            />
                        </Grid>

                    </Grid>
                </div>
                <Popup open={showPopupMaxSize} onClose={() => setShowPopupMaxSize(false)}>
                    <div className="popup-container" id="popup">
                        <div className="popup">
                            <h2 className="popup-title">¡Tamaño maximo excedido!</h2>
                            <p className="popup-message">Ha superado el tamaño máximo de archivo, elija una imagen de menor tamaño.</p>
                            <button className="popup-close" onClick={handleHidePopupMaxSize}>Cerrar</button>
                        </div>
                    </div>
                </Popup>
            </section>
        </>
    )
}
