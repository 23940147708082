import axios from 'axios';

export const login = (email, password) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password })
}

export const register = (json, config) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/directus/user`, json, config);
}

export const checkUser = (email) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/directus/user/exist/${email}`);
}
