import Router from './router/Router';
import './styles/main.scss';
import { UserProvider } from './providers/UserProvider.js'

function App() {
    return (
      <UserProvider>
        <Router />
      </UserProvider>
    );
}

export default App;
